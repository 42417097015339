import moment from "moment";
import Swal from "sweetalert2";
import * as TP from "./timePickers";
export const baseUrl = "https://sotbackend.leufstech.com";
// export const baseUrl = 'http://localhost:8000';
export const API_KEY = "3249234-2349sd9a-2303dsf023-d0312303fds-12302dw";

export const STORES = {
  NYKVARN: "Nykvarn",
  GJUTERIPORTEN_ST: "Gjuteriporten Södertälje",
  POLHEMSGATAN_ST: "Polhemsgatan Södertälje",
  FOODTRUCK1: "Foodtruck #1",
  UPPSALA: "Uppsala Boländerna",
  UPPSALA2: "Hölö",
};

// Handle HTTP errors since fetch won't.
export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const postData = async (endpoint = "", data = {}) => {
  // Default options are marked with *
  const url = baseUrl + endpoint;
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      API_KEY,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return await response.json();
};

export const getStorage = () => {
  const email = localStorage.getItem("email");

  return {
    email,
  };
};

export const errorSwal = (text) => {
  Swal.fire({
    title: "",
    text,
    icon: "error",
    confirmButtonColor: "#10AD7D",
    confirmButtonText: "OK",
  });
};

export const successSwal = (text) => {
  Swal.fire({
    title: "",
    text,
    icon: "success",
    confirmButtonColor: "#10AD7D",
    confirmButtonText: "OK",
  });
};

export const uniqueId = () => `_${Math.random().toString(36).substr(2, 9)}`;

export const timePickerSodertalje = () => {
  const momentNow = moment().add(5, "minutes");
  const whatDay = momentNow.isoWeekday();
  let tp = [];
  /*
  if (whatDay >= 2 && whatDay <= 3) {
    tp = TP.tpWeekdays1114();
  }
  */

  if (whatDay === 4) {
    tp = TP.tpWeekdays();
  }

  if (whatDay === 5) {
    tp = TP.tpFri();
  }

  if (whatDay === 6) {
    tp = TP.tpSatSunGp();
  }
  if (whatDay === 7) {
    tp = TP.tpSatSunGp();
  }

  let splicedTimePicker = [];
  for (let i = 0; i < tp.length; i++) {
    const selected = moment(tp[i], "HH:mm");
    if (selected.isSameOrAfter(momentNow)) {
      splicedTimePicker = tp.splice(i);
      break;
    }
  }
  splicedTimePicker.unshift("Tid för avhämtning");

  return splicedTimePicker;
};

export const timePickerPolhem = () => {
  const momentNow = moment().add(5, "minutes");
  const whatDay = momentNow.isoWeekday();
  let tp = [];

  if (whatDay === 1) {
    tp = TP.tpMondayPolhem();
  }
  if (whatDay >= 2 && whatDay < 5) {
    tp = TP.tpWeekdays();
  }

  if (whatDay === 5) {
    tp = TP.tpFri();
  }

  if (whatDay === 6) {
    tp = TP.tpWeekend();
  }

  if (whatDay === 7) {
    tp = TP.tpWeekend();
  }

  let splicedTimePicker = [];
  for (let i = 0; i < tp.length; i++) {
    const selected = moment(tp[i], "HH:mm");
    if (selected.isSameOrAfter(momentNow)) {
      splicedTimePicker = tp.splice(i);
      break;
    }
  }
  splicedTimePicker.unshift("Tid för avhämtning");

  return splicedTimePicker;
};

export const timePickerFoodtruck = () => {
  const momentNow = moment().add(5, "minutes");
  const whatDay = momentNow.isoWeekday();
  let tp = [];

  // 1 är måndag
  if (whatDay === 2) {
    tp = TP.tpFoodtruck();
  } else if (whatDay === 4) {
    tp = TP.tpFoodtruck();
  } else if (whatDay === 5) {
    tp = TP.tpFoodtruck();
  } else if (whatDay === 6) {
    tp = TP.tpFoodtruck();
  } else if (whatDay === 7) {
    tp = TP.tpFoodtruck();
  }

  let splicedTimePicker = [];
  for (let i = 0; i < tp.length; i++) {
    const selected = moment(tp[i], "HH:mm");
    if (selected.isSameOrAfter(momentNow)) {
      splicedTimePicker = tp.splice(i);
      break;
    }
  }
  splicedTimePicker.unshift("Tid för avhämtning");

  return splicedTimePicker;
};

export const timePickerUppsala = () => {
  const momentNow = moment().add(5, "minutes");
  const whatDay = momentNow.isoWeekday();
  let tp = [];

  if (whatDay === 3) {
    tp = TP.tpWed();
  }

  if (whatDay === 4) {
    tp = TP.tpWeekdays();
  }

  if (whatDay === 5) {
    tp = TP.tpFri();
  }

  if (whatDay === 6 || whatDay === 7) {
    tp = TP.tpSatSun();
  }

  let splicedTimePicker = [];
  for (let i = 0; i < tp.length; i++) {
    const selected = moment(tp[i], "HH:mm");
    if (selected.isSameOrAfter(momentNow)) {
      splicedTimePicker = tp.splice(i);
      break;
    }
  }
  splicedTimePicker.unshift("Tid för avhämtning");

  return splicedTimePicker;
};

export const timePickerUppsala2 = () => {
  const momentNow = moment().add(5, "minutes");
  const whatDay = momentNow.isoWeekday();
  let tp = [];

  if (whatDay === 2) {
    tp = TP.tpTisHoloTemp();
  }

  if (whatDay === 4) {
    tp = TP.tpTorHoloTemp();
  }

  if (whatDay === 5) {
    tp = TP.tpFriHoloTemp();
  }

  if (whatDay === 6) {
    tp = TP.tpSatSunHoloTemp();
  }

  if (whatDay === 7) {
    tp = TP.tpSatSunHoloTemp();
  }

  let splicedTimePicker = [];
  for (let i = 0; i < tp.length; i++) {
    const selected = moment(tp[i], "HH:mm");
    if (selected.isSameOrAfter(momentNow)) {
      splicedTimePicker = tp.splice(i);
      break;
    }
  }
  splicedTimePicker.unshift("Tid för avhämtning");

  return splicedTimePicker;
};

export const storeClosed = (selectedStore) => {
  const weekday = moment().weekday();
  // söndag = 0
  // mån 1, tis 2, ons 3, tors 4, fre 5, lör 6, sön 0

  /*
  if (selectedStore === STORES.POLHEMSGATAN_ST && weekday === 1) {
    return true;
  }
  */
  if (
    selectedStore === STORES.GJUTERIPORTEN_ST &&
    (weekday === 1 || weekday === 2 || weekday === 3)
  ) {
    return true;
  }
  /*
  if (selectedStore === STORES.FOODTRUCK1 && (weekday === 1 || weekday === 3)) {
    return true;
  }
  */
  if (selectedStore === STORES.UPPSALA && (weekday === 1 || weekday === 2)) {
    return true;
  }
  if (selectedStore === STORES.UPPSALA2 && (weekday === 1 || weekday === 3)) {
    return true;
  }

  return false;
};

export const storeClosedManually = (store, settings) => {
  const storeSettings =
    settings && settings.settings.filter((s) => s.store === store);
  const isClosed = storeSettings[0] && storeSettings[0].isClosed === 1;

  return isClosed;
};
