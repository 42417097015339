/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { fetchProducts } from "../redux/actions/productActions";
import { fetchSettings } from "../redux/actions/settingActions";
import Loader from "./Loader";
import Footer from "./Footer";
import {
  errorSwal, STORES, storeClosed, storeClosedManually,
} from "../util";

import "./Home.css";

const Home = ({
  fetchProducts,
  fetchSettings,
  settings,
  isFetchingSettings,
  products,
}) => {
  const history = useHistory();
  const [store, setStore] = useState("Välj ställe för avhämtning");
  useEffect(() => {
    localStorage.setItem("orderList", JSON.stringify([]));
    localStorage.setItem("discountedOrderSum", 0);
    localStorage.setItem("discountedOrderList", JSON.stringify([]));
    localStorage.setItem("deliveryTime", null);
    localStorage.setItem("orderSum", 0);
    fetchProducts();
    fetchSettings();
    const hasStore = localStorage.getItem("selectedStore");
    if (
      hasStore !== "null"
      && hasStore != null
      && hasStore !== ""
      && hasStore !== "Välj ställe för avhämtning"
    ) {
      setStore(hasStore);
    }
  }, [fetchProducts, fetchSettings]);
  // eslint-disable-next-line react/prop-types
  const storeSettings = settings && settings.settings.filter((s) => s.store === store);
  // eslint-disable-next-line react/prop-types
  const serverSettings = storeSettings.length > 0 ? storeSettings[0] : settings.settings[0];
  const serverClientVersion = serverSettings && serverSettings.clientVersion;
  const storedSettings = JSON.parse(localStorage.getItem("settings"));
  const storedClientVersion = storedSettings && storedSettings.clientVersion;
  const timeNow = moment();
  const afterClosed = timeNow.isAfter(moment("20:31", "HH:mm"));
  if (
    serverClientVersion != null
    && serverClientVersion !== storedClientVersion
  ) {
    console.log("UPDATE VERSION");
    localStorage.setItem("orderList", JSON.stringify([]));
    localStorage.setItem("discountedOrderSum", 0);
    localStorage.setItem("discountedOrderList", JSON.stringify([]));
    localStorage.setItem("deliveryTime", null);
    localStorage.setItem("orderSum", 0);
    localStorage.setItem("selectedStore", "Välj ställe för avhämtning");
    localStorage.setItem("lunchTime", false);
    window.location.reload(true);
  }
  if (serverSettings) {
    localStorage.setItem("settings", JSON.stringify(serverSettings));
  }

  if (products) {
    localStorage.setItem("products", JSON.stringify(products));
  }

  const sodertaljeUrl = "https://www.google.se/maps/place/Hertig+Carls+v%C3%A4g+8,+151+38+S%C3%B6dert%C3%A4lje/@59.1850901,17.6350715,17z/data=!3m1!4b1!4m5!3m4!1s0x465f12bb4c723b4d:0xfe2b176598d05443!8m2!3d59.1850901!4d17.6372601";
  const sodertaljePolhemUrl = "https://www.google.se/maps/place/Polhemsgatan+3A,+152+40+S%C3%B6dert%C3%A4lje,+Sverige/@59.200129,17.6295728,17z/data=!3m1!4b1!4m5!3m4!1s0x465f0d604e134a8f:0x8c43eb6eb94816c!8m2!3d59.200129!4d17.6317615";
  const uppsalaUrl = "https://www.google.se/maps/place/Bolandsgatan+16,+753+23+Uppsala/@59.8546805,17.6814136,17z/data=!3m1!4b1!4m5!3m4!1s0x465fc94d0968cbeb:0x9376fcdef434cd92!8m2!3d59.8546778!4d17.6836023?hl=sv";
  const uppsala2Url = "https://www.google.com/maps/place/Wijbacksv%C3%A4gen,+153+71+H%C3%B6l%C3%B6/@59.0274508,17.5490743,17z/data=!3m1!4b1!4m6!3m5!1s0x465f1553313acd87:0x4758c3e27de65448!8m2!3d59.0274508!4d17.5516492!16s%2Fg%2F1tdm6ynd?entry=ttu";

  const gotoMaps = (mapUrl) => {
    window.location.href = mapUrl;
  };

  if (isFetchingSettings && !serverSettings) {
    return <Loader />;
  }

  const goToPizzas = () => {
    const selectedStore = localStorage.getItem("selectedStore");
    if (
      selectedStore != "null"
      && selectedStore != null
      && selectedStore !== ""
      && selectedStore !== "Välj ställe för avhämtning"
    ) {
      if (
        serverSettings
        && serverSettings.showInfoText
        && serverSettings.showInfoText.length > 0
      ) {
        Swal.fire({
          title: "",
          text: serverSettings.showInfoText,
          icon: "info",
          confirmButtonColor: "#6dd1a0",
          confirmButtonText: "OK",
        });
      }
      window.scrollTo(0, 0);
      history.push("/pizzas");
    } else {
      errorSwal("Du måste välja en upphämtningsplats innan du kan gå vidare.");
    }
  };

  const selectStore = (s) => {
    localStorage.setItem("selectedStore", s);
    setStore(s);
  };

  return (
    <div>
      {serverSettings
        && serverSettings.showInfoText
        && serverSettings.showInfoText.length > 0 && (
          <div className="home_flash_div">
            <span className="home_flash_text">
              {serverSettings.showInfoText}
            </span>
          </div>
      )}

      <h1 className="header">SOT</h1>
      <div className="home_container">
        <span className="home_welcomeText">
          Stenugnsbakad pizza på napolitanskt vis för avhämtning.
        </span>
        <span className="home_welcomeText2">
          Här beställer ni, väljer en tid för avhämtning och betalar, du får då
          ett ordernummer samt ett kvitto på mailen. Ange ordernummer vid
          avhämtning. Lunchpris 120:- gäller mellan 11.00-14.00 på vardagar.
          Gäller ej månadens eller Pizza Di lusso.
        </span>

        <div className="home_storePickerContainer">
          <select
            className="home_storePickerDropdown"
            onChange={(e) => selectStore(e.target.value)}
            value={store}
          >
            <option value="Välj ställe för avhämtning">
              Välj ställe för avhämtning
            </option>
            <option value={STORES.GJUTERIPORTEN_ST}>
              {STORES.GJUTERIPORTEN_ST}
              {" "}
              {(storeClosed(STORES.GJUTERIPORTEN_ST)
                || afterClosed
                || storeClosedManually(STORES.GJUTERIPORTEN_ST, settings))
                && "(Stängt)"}
            </option>
            <option value={STORES.POLHEMSGATAN_ST}>
              {STORES.POLHEMSGATAN_ST}
              {" "}
              {(storeClosed(STORES.POLHEMSGATAN_ST)
                || afterClosed
                || storeClosedManually(STORES.POLHEMSGATAN_ST, settings))
                && "(Stängt)"}
            </option>
            <option value={STORES.FOODTRUCK1}>
              {STORES.FOODTRUCK1}
              {" "}
              {(storeClosed(STORES.FOODTRUCK1)
                || afterClosed
                || storeClosedManually(STORES.FOODTRUCK1, settings))
                && "(Stängt)"}
            </option>
            <option value={STORES.UPPSALA}>
              {STORES.UPPSALA}
              {" "}
              {(storeClosed(STORES.UPPSALA)
                || afterClosed
                || storeClosedManually(STORES.UPPSALA, settings))
                && "(Stängt)"}
            </option>
            <option value={STORES.UPPSALA2}>
              {STORES.UPPSALA2}
              {" "}
              {(storeClosed(STORES.UPPSALA2)
                || afterClosed
                || storeClosedManually(STORES.UPPSALA2, settings))
                && "(Stängt)"}
            </option>
          </select>
        </div>

        {store === STORES.GJUTERIPORTEN_ST && (
          <div className="home_findusContainer">
            <span className="home_placeHeader">{STORES.GJUTERIPORTEN_ST}</span>
            <div>
              <br />
              <span className="home_placeOpenTimesLeft">SOMMARSTÄNGT (Hänvisar till Polhemsgatan 3A)</span>
              <br />
              <br />
              {/*
              <span className="home_placeOpenTimesLeft">tors:</span>
              {" "}
              <span className="home_placeOpenTimesRight">11.00-19.30</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">fre: </span>
              <span className="home_placeOpenTimesRight">11.00-20.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">lör:</span>
              {" "}
              <span className="home_placeOpenTimesRight">16.00-20.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">sön:</span>
              {" "}
              <span className="home_placeOpenTimesRight">16.00-20.00</span>
            </div>
              */ }
             </div> 
              
            <button
              type="button"
              className="home_iconButton"
              onClick={() => gotoMaps(sodertaljeUrl)}
            >
              <FontAwesomeIcon className="home_mapIcon" icon={faMapPin} />
              <span className="home_mapText">
                Hertig Carls väg 8, Södertälje
              </span>
            </button>
          </div>
        )}

        {store === STORES.POLHEMSGATAN_ST && (
          <div className="home_findusContainer">
            <span className="home_placeHeader">
              {STORES.POLHEMSGATAN_ST}
              {" "}
              | Utökad meny
            </span>
            <div>
              <span className="home_placeOpenTimesLeft">mån:</span>
              {" "}
              <span className="home_placeOpenTimesRight">11.00-14.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">tis-tors:</span>
              {" "}
              <span className="home_placeOpenTimesRight">11.00-19.30</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">fre: </span>
              <span className="home_placeOpenTimesRight">11.00-20.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">lör-sön: </span>
              <span className="home_placeOpenTimesRight">12.00-20.00</span>
            </div>
            <button
              type="button"
              className="home_iconButton"
              onClick={() => gotoMaps(sodertaljePolhemUrl)}
            >
              <FontAwesomeIcon className="home_mapIcon" icon={faMapPin} />
              <span className="home_mapText">Polhemsgatan 3A, Södertälje</span>
            </button>
          </div>
        )}

        {store === STORES.FOODTRUCK1 && (
          <div className="home_findusContainer">
            <span className="home_placeHeader">{STORES.FOODTRUCK1}</span>
            <div>
              <div>
                <span className="home_placeOpenTimesLeft">
                  16.00-20.00
                  {/*
                  <button
                    type="button"
                    className="home_iconButton"
                    onClick={() => gotoMaps(tcglastrosa)}
                  >
                    <span className="home_mapText">(TC Glas Trosa)</span>
                  </button>
              */}
                </span>
                <br />
              </div>
            </div>
          </div>
        )}

        {store === STORES.UPPSALA && (
          <div className="home_findusContainer">
            <span className="home_placeHeader">{STORES.UPPSALA}</span>
            <div>
              <span className="home_placeOpenTimesLeft">ons:</span>
              {" "}
              <span className="home_placeOpenTimesRight">17.00-19.30</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">tors:</span>
              {" "}
              <span className="home_placeOpenTimesRight">11.00-19.30</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">fre:</span>
              {" "}
              <span className="home_placeOpenTimesRight">11.00-20.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">lör-sön:</span>
              {" "}
              <span className="home_placeOpenTimesRight">12.00-20.00</span>
            </div>

            <button
              type="button"
              className="home_iconButton"
              onClick={() => gotoMaps(uppsalaUrl)}
            >
              <FontAwesomeIcon className="home_mapIcon" icon={faMapPin} />
              <span className="home_mapText">Bolandsgatan 16 Uppsala</span>
            </button>
          </div>
        )}

        {store === STORES.UPPSALA2 && (
          <div className="home_findusContainer">
            <span className="home_placeHeader">{STORES.UPPSALA2}</span>
            <div>
              <span className="home_placeOpenTimesLeft">tors:</span>
              {" "}
              <span className="home_placeOpenTimesRight">16.00-20.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">fre:</span>
              {" "}
              <span className="home_placeOpenTimesRight">16.00-20.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">lör:</span>
              {" "}
              <span className="home_placeOpenTimesRight">14.00-20.00</span>
            </div>
            <div>
              <span className="home_placeOpenTimesLeft">sön:</span>
              {" "}
              <span className="home_placeOpenTimesRight">12.00-20.00</span>
            </div>

            <button
              type="button"
              className="home_iconButton"
              onClick={() => gotoMaps(uppsala2Url)}
            >
              <FontAwesomeIcon className="home_mapIcon" icon={faMapPin} />
              <span className="home_mapText">Wijbacksvägen, 15371 Hölö</span>
            </button>
          </div>
        )}

        <div className="home_toMenuButtonContainer">
          <button
            type="button"
            className={
              store === "Välj ställe för avhämtning"
                ? "home_toMenuButton_disabled"
                : "home_toMenuButton"
            }
            onClick={() => goToPizzas()}
          >
            Se menyn
            {" "}
            {storeClosed(localStorage.getItem("selectedStore"))
              || (storeClosedManually(
                localStorage.getItem("selectedStore"),
                settings,
              )
                && "(Stängt)")}
          </button>
        </div>
      </div>
      <div className="home_deadSpace" />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.items,
  isFetingProducts: state.products.loading,
  error: state.products.error,
  settings: state.settings,
  isFetchingSettings: state.settings.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(fetchProducts()),
  fetchSettings: () => dispatch(fetchSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
